.control-dots-service > li > h2{
  font-family: 'Playfair Display';
}

#services{
  background-color: #F5E5CE;
}


.dot {
  color: #a89c93;
} /*# sourceMappingURL=services.css.map */

.control-dots-service > li {
  color: #A89C93;
  padding-left: 10px;
  padding-right: 10px;
}

.iframe-wrapper{
  padding-bottom: 56.25%;
  overflow: hidden;
  z-index: 1;
}

.iframe-video{
  overflow: hidden;
  border: 0;
  align-self: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  aspect-ratio: 16/9;
}

li[class="active-text"] {
  color: #472F1E;
}

.service-video-container{
  /* border: red solid; */
}


.ceVideo-wrapper{
  height: calc(100vh - 130px);
  box-sizing: border-box;
  overflow: hidden;
  /* background-color: green; */
}

@media (max-width: 768px) {
  .control-dots > li {
    font-size: 10px;
  }

  .ceVideo-wrapper{
    height: auto;
  }
}

@media(min-width: 1400px) and (min-height: 780px){
  .ipad-service-text > li > h2{
    font-size: 25px;
  }
}

@media (max-width: 1380px) and (max-height: 1200px) {
  .ipad-service-text > li > h2{
    font-size: 25px;
  }

}

@media (max-width: 1100px) and (max-height: 1400px) {
  #services{
    height: auto;
  }

  .ceVideo-wrapper{
    height: calc(60vh - 140px);
  }
}


@media (max-width: 850px) and (max-height: 1200px) {
  .ipad-service-text > li > h2{
    font-size: 16px;
  }

  #services{
    height: auto;
  }

  .ceVideo-wrapper{
    height: calc(60vh - 140px);
  }
}

@media (max-width: 780px) and (max-height: 1120px) {

  .ceVideo-wrapper{
    height: calc(60vh - 180px);
  }

  .ipad-service-text > li > h2{
    font-size: 17px;
  }
}



@media (max-width: 530px) and (max-height: 1000px){
  .ipad-service-text > li > h2{
    font-size: 9px;
  }

  .ceVideo-wrapper{
    height: calc(60vh - 90px);
  }
}




