.work-title {
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
}

.work-modal-title {
  font-family: "Playfair Display";
  font-weight: 600;
  color: #a89c93;
}

.item {
  position: relative;
  min-width: 240px;
  min-height: 210px;
}

.item-initial {
  position: absolute;
  opacity: 1;
  width: 100%;
  height: auto;
  transition: 0.5s all ease-in-out;
}

.item-hover {
  position: absolute;
  opacity: 0;
  transition: 1s all ease-in-out;
  width: 100%;
  height: auto;
}

@media (hover: hover) {
  .item:hover {
    cursor: pointer;
  }

  .item:hover .item-initial {
    opacity: 0;
  }

  .item:hover .item-hover {
    opacity: 1;
  }

  .item:hover .work-modal-title {
    color: #472f1e;
  }
}

.itemHovered{
  opacity: 1;
}




@media (min-width: 820px) and (min-height: 1180px) {
  #works {
    height: auto;
  }
}

@media (min-width: 1440px) and (min-height: 900px) {
  .item {
    min-width: 270px;
    min-height: 270px;
  }
}
