@import "./datauris";

body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  background-color: white;
}
body #root {
  overflow-x: hidden;
}

.carouselservice {
  .__carousel-indicator {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

    li {
      width: 10px;
      height: 10px;
      background: #472f1e99;
      border-radius: 50%;
      transition: 0.3s ease-in-out;
      &.active {
        width: 13px;
        height: 13px;
        background: #472f1e;
      }
    }
    li:hover {
      cursor: pointer;
    }
  }

  .__carousel-item {
    display: none;
    &.active {
      display: block;
    }
  }
  .__carousel-nav {
    display: flex;
    margin-top: 1em;
    gap: 0.5rem;
    .__prev,
    .__next {
      // position: absolute;
      top: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      cursor: pointer;
      transition: all 0.25s ease;
      span {
        width: 100%;
        height: 100%;
      }
    }
    .__prev {
      left: 5%;
      // &:hover {
      //   margin-left: -5px;
      // }
      span {
        background: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 0.75C11.1594 0.75 0.75 11.1594 0.75 24C0.75 36.8406 11.1594 47.25 24 47.25C36.8406 47.25 47.25 36.8406 47.25 24C47.25 11.1594 36.8406 0.75 24 0.75Z' stroke='white' stroke-width='1.5'/%3E%3Cpath d='M30.9736 22.951H19.5006L24.5156 17.806C24.7092 17.6044 24.8173 17.3356 24.8173 17.056C24.8173 16.7765 24.7092 16.5077 24.5156 16.306C24.4222 16.208 24.3098 16.13 24.1854 16.0767C24.0609 16.0233 23.927 15.9958 23.7916 15.9958C23.6562 15.9958 23.5222 16.0233 23.3977 16.0767C23.2733 16.13 23.161 16.208 23.0676 16.306L16.3006 23.257C16.1078 23.4566 16.0001 23.7231 16.0001 24.0005C16.0001 24.278 16.1078 24.5445 16.3006 24.7441L23.0676 31.692C23.161 31.7901 23.2733 31.8681 23.3977 31.9214C23.5222 31.9748 23.6562 32.0022 23.7916 32.0022C23.927 32.0022 24.0609 31.9748 24.1854 31.9214C24.3098 31.8681 24.4222 31.7901 24.5156 31.692C24.7083 31.4925 24.8161 31.226 24.8161 30.9485C24.8161 30.6711 24.7083 30.4046 24.5156 30.205L19.5006 25.0601H30.9736C31.2476 25.0516 31.5076 24.9368 31.6985 24.74C31.8894 24.5431 31.9961 24.2797 31.9961 24.0055C31.9961 23.7314 31.8894 23.468 31.6985 23.2711C31.5076 23.0743 31.2476 22.9595 30.9736 22.951Z' fill='white'/%3E%3C/svg%3E%0A")
          center/contain no-repeat;
      }
    }
    .__next {
      right: 5%;
      // &:hover {
      //   margin-right: -5px;
      // }
      span {
        background: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 47.25C36.8406 47.25 47.25 36.8406 47.25 24C47.25 11.1594 36.8406 0.75 24 0.75C11.1594 0.75 0.75 11.1594 0.75 24C0.75 36.8406 11.1594 47.25 24 47.25Z' stroke='white' stroke-width='1.5'/%3E%3Cpath d='M17.0264 25.049H28.4994L23.4844 30.194C23.2908 30.3956 23.1827 30.6644 23.1827 30.944C23.1827 31.2235 23.2908 31.4923 23.4844 31.694C23.5778 31.792 23.6902 31.87 23.8146 31.9233C23.9391 31.9767 24.073 32.0042 24.2084 32.0042C24.3438 32.0042 24.4778 31.9767 24.6023 31.9233C24.7267 31.87 24.839 31.792 24.9324 31.694L31.6994 24.743C31.8922 24.5434 31.9999 24.2769 31.9999 23.9995C31.9999 23.722 31.8922 23.4555 31.6994 23.2559L24.9324 16.308C24.839 16.2099 24.7267 16.1319 24.6023 16.0786C24.4778 16.0252 24.3438 15.9978 24.2084 15.9978C24.073 15.9978 23.9391 16.0252 23.8146 16.0786C23.6902 16.1319 23.5778 16.2099 23.4844 16.308C23.2917 16.5075 23.1839 16.774 23.1839 17.0515C23.1839 17.3289 23.2917 17.5954 23.4844 17.795L28.4994 22.9399H17.0264C16.7524 22.9484 16.4924 23.0632 16.3015 23.26C16.1106 23.4569 16.0039 23.7203 16.0039 23.9945C16.0039 24.2686 16.1106 24.532 16.3015 24.7289C16.4924 24.9257 16.7524 25.0405 17.0264 25.049Z' fill='white'/%3E%3C/svg%3E%0A")
          center/contain no-repeat;
      }
    }
  }
}

.carousel {
  .__carousel-indicator {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

    li {
      width: 10px;
      height: 10px;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      transition: 0.3s ease-in-out;
      &.active {
        width: 13px;
        height: 13px;
        background: rgba(255, 255, 255, 1);
      }
    }
    li:hover {
      cursor: pointer;
    }
  }

  .__carousel-item {
    display: none;
    &.active {
      display: block;
    }
  }
  .__carousel-nav {
    display: flex;
    margin-top: 1em;
    gap: 0.5rem;
    .__prev,
    .__next {
      // position: absolute;
      top: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      cursor: pointer;
      transition: all 0.25s ease;
      span {
        width: 100%;
        height: 100%;
      }
    }
    .__prev {
      left: 5%;
      // &:hover {
      //   margin-left: -5px;
      // }
      span {
        background: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 0.75C11.1594 0.75 0.75 11.1594 0.75 24C0.75 36.8406 11.1594 47.25 24 47.25C36.8406 47.25 47.25 36.8406 47.25 24C47.25 11.1594 36.8406 0.75 24 0.75Z' stroke='white' stroke-width='1.5'/%3E%3Cpath d='M30.9736 22.951H19.5006L24.5156 17.806C24.7092 17.6044 24.8173 17.3356 24.8173 17.056C24.8173 16.7765 24.7092 16.5077 24.5156 16.306C24.4222 16.208 24.3098 16.13 24.1854 16.0767C24.0609 16.0233 23.927 15.9958 23.7916 15.9958C23.6562 15.9958 23.5222 16.0233 23.3977 16.0767C23.2733 16.13 23.161 16.208 23.0676 16.306L16.3006 23.257C16.1078 23.4566 16.0001 23.7231 16.0001 24.0005C16.0001 24.278 16.1078 24.5445 16.3006 24.7441L23.0676 31.692C23.161 31.7901 23.2733 31.8681 23.3977 31.9214C23.5222 31.9748 23.6562 32.0022 23.7916 32.0022C23.927 32.0022 24.0609 31.9748 24.1854 31.9214C24.3098 31.8681 24.4222 31.7901 24.5156 31.692C24.7083 31.4925 24.8161 31.226 24.8161 30.9485C24.8161 30.6711 24.7083 30.4046 24.5156 30.205L19.5006 25.0601H30.9736C31.2476 25.0516 31.5076 24.9368 31.6985 24.74C31.8894 24.5431 31.9961 24.2797 31.9961 24.0055C31.9961 23.7314 31.8894 23.468 31.6985 23.2711C31.5076 23.0743 31.2476 22.9595 30.9736 22.951Z' fill='white'/%3E%3C/svg%3E%0A")
          center/contain no-repeat;
      }
    }
    .__next {
      right: 5%;
      // &:hover {
      //   margin-right: -5px;
      // }
      span {
        background: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 47.25C36.8406 47.25 47.25 36.8406 47.25 24C47.25 11.1594 36.8406 0.75 24 0.75C11.1594 0.75 0.75 11.1594 0.75 24C0.75 36.8406 11.1594 47.25 24 47.25Z' stroke='white' stroke-width='1.5'/%3E%3Cpath d='M17.0264 25.049H28.4994L23.4844 30.194C23.2908 30.3956 23.1827 30.6644 23.1827 30.944C23.1827 31.2235 23.2908 31.4923 23.4844 31.694C23.5778 31.792 23.6902 31.87 23.8146 31.9233C23.9391 31.9767 24.073 32.0042 24.2084 32.0042C24.3438 32.0042 24.4778 31.9767 24.6023 31.9233C24.7267 31.87 24.839 31.792 24.9324 31.694L31.6994 24.743C31.8922 24.5434 31.9999 24.2769 31.9999 23.9995C31.9999 23.722 31.8922 23.4555 31.6994 23.2559L24.9324 16.308C24.839 16.2099 24.7267 16.1319 24.6023 16.0786C24.4778 16.0252 24.3438 15.9978 24.2084 15.9978C24.073 15.9978 23.9391 16.0252 23.8146 16.0786C23.6902 16.1319 23.5778 16.2099 23.4844 16.308C23.2917 16.5075 23.1839 16.774 23.1839 17.0515C23.1839 17.3289 23.2917 17.5954 23.4844 17.795L28.4994 22.9399H17.0264C16.7524 22.9484 16.4924 23.0632 16.3015 23.26C16.1106 23.4569 16.0039 23.7203 16.0039 23.9945C16.0039 24.2686 16.1106 24.532 16.3015 24.7289C16.4924 24.9257 16.7524 25.0405 17.0264 25.049Z' fill='white'/%3E%3C/svg%3E%0A")
          center/contain no-repeat;
      }
    }
  }
}
@media (hover: none) and (pointer: coarse) {
  /* ... */
  .carousel {
    .__carousel-content {
      opacity: 0;
      &.show {
        opacity: 1;
        transition: opacity 2s ease-out;
      }
    }
  }

  .carouselservice {
    .__carousel-content {
      opacity: 0;
      &.show {
        opacity: 1;
        transition: opacity 2s ease-out;
      }
    }
  }
}

/* HAMBURGER MENU */
.hideMenuNav {
  display: none;
}

.showMenuNav {
  // display: block;
  position: absolute;
  width: 100%;
  height: 75vh;
  top: 0;
  left: 0;
  background: white;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.__button {
  text-align: center;
  transition: all 0.5s;
  background-size: 200% auto;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  &.button-1 {
    background-image: linear-gradient(
      to left,
      rgba(156, 18, 18, 0.2) 30%,
      rgba(255, 255, 255, 0.2) 40%,
      rgba(156, 18, 18, 0.315) 100%
    );
    color: white;
    text-transform: uppercase;
    &:hover {
      box-shadow: 0px 0px 15px 0px rgb(244, 172, 172),
        0px 0px 40px 0px rgba(255, 49, 42, 0.421);
      border: 2px solid;
      border-color: rgba(255, 255, 255, 0.7);
      background-position: right center;
    }
  }
  &.button-2 {
    box-shadow: 0 0 20px #eee;
    border: 1px solid;
    border-color: transparent;
    flex: 1 1 auto;
    background-image: linear-gradient(
      to left,
      rgba(156, 18, 18, 0.7) 0%,
      rgba(240, 100, 100, 0.5) 51%,
      rgba(156, 18, 18, 0.7) 100%
    );
    color: white;
    text-transform: uppercase;
    &:hover {
      box-shadow: 0px 0px 15px 0px rgba(156, 18, 18, 1),
        0px 0px 30px 0px rgba(255, 49, 42, 0.421);
      border: 1px solid;
      border-color: rgba(255, 222, 193, 0.846);
      background-position: right center;
    }
  }
  &.button-3 {
    border: 1px solid;
    border-color: transparent;
    flex: 1 1 auto;
    background-image: linear-gradient(
      to left,
      rgba(156, 18, 18, 0) 0%,
      rgba(240, 100, 100, 0) 51%,
      rgba(156, 18, 18, 0) 100%
    );
    text-shadow: 0px 0px 1px #fd3535;
    &:hover {
      box-shadow: 0px 0px 20px 0px rgb(239, 164, 164);
      border: 1px solid;
      // border-radius: 1.25rem;
      border-color: rgba(248, 129, 129, 0.846);
      background-position: right center;
      text-shadow: 0px 0px 1px #fd3535;
      color: #891111;
    }
    &.button-3-2 {
      &:hover {
        box-shadow: 0px 0px 40px 0px rgba(248, 129, 129, 0.846);
        color: #9c1212;
      }
    }
  }
  &.button-4 {
    border: 2px solid #9c1212;
    flex: 1 1 auto;
    // background-image: linear-gradient(to left, rgba(156,18,18, 0) 0%, rgba(255, 49, 42, 0.421) 51%, rgba(156,18,18, 0) 100%);

    &:hover {
      box-shadow: 0px 0px 15px 2px rgba(255, 49, 42, 0.921);
      border: 2px solid;
      border-color: rgba(255, 62, 62, 1);
      background-position: right center;
      text-shadow: 0px 0px 1px rgb(249, 140, 140);
      color: rgb(255, 253, 253);
    }
  }
  &:hover {
    // transform: scale(1.025);
  }
}

.caters {
  .caters-item {
    position: relative;
    overflow: hidden;
    // height: 100vh;

    img,
    content {
      transition: all 0.35s ease-in;
    }
    .content {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      background: rgba(38, 25, 0, 0.65);
      opacity: 0;
    }

    &:hover {
      img {
        transform: scale(1.15);
      }
      .content {
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .carousel {
    .__carousel-nav {
      .__prev,
      .__next {
        width: 25px !important;
        height: 25px !important;
      }
      .__prev {
        left: 2% !important;
      }
      .__next {
        right: 2% !important;
      }
    }
  }

  .carouselservice {
    .__carousel-nav {
      .__prev,
      .__next {
        width: 25px !important;
        height: 25px !important;
      }
      .__prev {
        left: 2% !important;
      }
      .__next {
        right: 2% !important;
      }
    }
  }
}
