#contact {
}

.font-playfair-italic {
  font-family: 'Playfair Display';
  font-style: italic;
}

.form-header {
  color: white;
  font-family: 'Inter';
  font-weight: 300;
  font-size: 14px;
}

.bold-contact {
  font-weight: 600;
}

.form-container {
  z-index: 9;
  color: white;
}

.contact-img {
  z-index: -1;
}

.form-content {
  width: 70%;
  background-color: transparent;
}

.form-input {
  height: 30px;
}
.form-input,
.form-message {
  border: solid white;
  margin: 10px;
  /* background-color: transparent; */
  text-align: center;
  /* color: grey; */
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
  font-family: 'Inter';
  font-size: 11px;
}

.contact-submit {
  font-family: 'Inter';
  font-size: 11px;
}

.form-message {
  height: 70px;
  resize: none;
  padding: 1.75rem;
}

@media (max-width: 400px) {
  .form-content {
    width: 100%;
    /* background-color: red; */
  }
}

@media (max-width: 768px) {
  .form-content {
    width: 90%;
  }

  .form-input {
    height: 40px;
  }
}

@media (width: 820px) and (height: 1180px) {
  .form-input {
    height: 40px;
  }

  .form-message {
    height: 90px;
    line-height: calc(100 * 7.5%);
  }

  #contact {
    height: auto;
  }
}

@media (min-width: 950px) and (min-height: 1250px) {
  #contact {
    height: auto;
  }

  .form-header{
    margin-top: 2rem;
  }
}

@media (width: 1180px) and (height: 820px) {
  .form-input {
    height: 40px;
  }

  .form-message {
    height: 100px;
    line-height: calc(100 * 8%);
  }
}

@media (max-width: 1380px) and (max-height: 1200px) {
  #contact {
    height: auto;
  }

  #works {
    height: auto;
  }
}
