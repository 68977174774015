.footer-container{
    background-color: #472F1E;
    color: #D0C2B0;
}

.footer-first{
    border-right: #665244 solid 0.5px;
    border-bottom: #665244 solid 0.5px;
    font-size: 11px;
    font-weight: 400;
}

.first-subtitle{
    font-size: 10px;
    font-weight: 800;
}

.first-description{
    font-size: 10px;
    width: 100%;
    font-weight: 300;
}


.footer-second{
    border-bottom: #665244 solid 0.5px;
    font-size: 11px;
    font-weight: 400;
}

.footer-third{
    border-right: #665244 solid 0.5px;
    border-bottom: #665244 solid 0.5px;
    font-size: 11px;
    font-weight: 400;
    /* overflow: scroll; */
}

.footer-fourth{
    border-bottom: #665244 solid 0.5px;
    font-size: 11px;
    font-weight: 400;
}

.footer-instagram{
    border-right: #665244 solid 0.5px;
    border-bottom: #665244 solid 0.5px;
    font-size: 11px;
    transition: 0.3s;

}

.footer-instagram:hover{
    cursor: pointer;
    background-color: #6b462d;
}

.footer-facebook{
    border-right: #665244 solid 0.5px;
    border-bottom: #665244 solid 0.5px;
    font-size: 11px;
    transition: 0.3s;

}

.footer-facebook:hover{
    cursor: pointer;
    background-color: #6b462d;
}

.footer-end{
    font-size: 12px;
    font-weight: 400;
}

@media(max-width:719px){
    .first-description{
        width: 100%;
    }

    .footer-end{
        font-size: 9px;
    }

    .footer-second{
        display: grid;
        align-items: center;
    }

    .footer-fourth{
        font-size: 10px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media(max-width:500px){
    .footer-first, .footer-second, .footer-third, .footer-fourth, .footer-facebook, .footer-instagram{
        font-size: 10px;
    }

    .first-description{
        font-size: 7px;
    }

    .footer-end{
        font-size: 7px;
    }
}

@media (min-width: 1720px){
    .footer-first, .footer-second, .footer-third, .footer-fourth, .footer-facebook, .footer-instagram{
        font-size: 20px;
    }

    .first-description{
        font-size: 15px;
    }

    .footer-end{
        font-size: 15px;
    }
}

