.line-nav{
    width: 100%;
    height: 1px;
    /* left: 12.1%; */
    left: 110%;
    width: 100vw;
    background-color: #F5F5F5;
    /* transform: translateX(12%); */
}

.active-nav{
    border-bottom: #F5F5F5 solid 3px;
}

.inactive-nav{
    font-weight: 400 !important;
}

.__header-wrapper{
    /* overflow-x: hidden; */
}

.header-logo{
    width: 150px;
}

.MENU-LINK-MOBILE-OPEN > li  > a{
    text-decoration: inherit;
    color: black;
}

.MENU-LINK-MOBILE-OPEN{
    padding-left: 0;
    margin-bottom: 0;
}

@media(max-width: 1074px){
    .line-nav{
        display: none;
    }
}

@media (max-width: 769px) {
    .header-logo{
        width: 120px;
    }

    #mobile_carousel_header{
        margin-top: 70px;
    }
}

@media (min-width: 1720px){
    .active-nav, .inactive-nav{
        font-size: 25px;
    }

    .__navigation{
        padding-bottom: 45px;
    }  
}

@media (max-width: 565px){
    .header-logo{
        width: 80px;
    }
}

@media (min-width: 760px) and (min-height: 1120px) {

}