@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&family=Playfair+Display:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Playfair+Display:ital,wght@0,400;0,500;0,700;0,800;0,900;1,400;1,700&display=swap');

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */ 
  @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,700;0,800;0,900;1,400;1,700&display=swap');
}

@supports not (-webkit-touch-callout: none) {
  /* CSS for other than iOS devices */ 
  @font-face {
    font-family: Playfair;
    src: url(./fonts/Playfair/Playfair-global.ttf) format('truetype');
  }
  
  @font-face {
    font-family: PlayfairItalic;
    src: url(./fonts/Playfair/Playfair-italic.ttf) format('truetype');
  }
}

@font-face {
  font-family: Ghaitsa;
  src: url(./fonts/ghaitsa/Ghaitsa.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./fonts/Poppins/Poppins-Regular.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: Poppins;
  src: url(./fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: Poppins;
  src: url(./fonts/Poppins/Poppins-Bold.ttf) format('truetype');
  font-weight: bold;
}

/* @font-face {
  font-family: Inter;
  src: url(./fonts/Inter/inter-global.ttf) format('truetype');
} */

body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-weight: normal;
}
body {
  background-color: #EEEDF4;
}

h1,h2,h3,h4,h5,h6,.font-ghaitsa {
  font-family: 'Playfair Display';
}

/* .font-heading {
  font-family: adineuePROCyr-BoldWeb, sans-serif;
} */

.font-playfair {
  font-family: 'Playfair Display';
}


section {
  /* min-height: 100vh; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
}


@media (max-width: 1366px) and (min-width: 1366px) {
  .video-container > div {
    aspect-ratio: 16/9 !important;
    top: 51% !important;
  }
}
@media (max-width: 1024px) and (min-width: 1024px) {
  .video-container > div {
    aspect-ratio: 16/9 !important;
    top: 51% !important;
  }
}