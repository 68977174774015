.about-caption {
  font-family: "Inter";
  font-weight: 300;
}

.about-body {
  font-family: "Inter";
}

.about-title {
  font-family: "Playfair Display";
  font-weight: 500;
}

.ipad-height{
  height: 100vh;
}

@media (max-width: 769px) {
  .about-caption {
    font-size: 8px;
    line-height: 1;
  }

  .about-title {
    font-size: 12px;
    line-height: 1;
  }
}

@media (max-width: 500px) {
  .about-caption {
    font-size: 7px;
    line-height: 1;
  }

  .about-title {
    font-size: 10px;
    line-height: 1;
  }

  .about-header {
    top: 25%;
  }
}

@media (min-width: 760px) and (min-height: 1120px) {
  .ipad-header {
    bottom: 8%;
  }

  .ipad-about-img {
    margin-top: 0;
  }

  .caters {
    height: auto;
  }

  .ipad-carousel {
    top: 30%;
  }

  .ipad-height {
    height: auto;
  }
}

@media (min-width: 350px) and (min-height: 800px) {
  .ipad-header {
    bottom: 5%;
  }
}

@media (min-width: 750px) and (min-height: 1000px) {
  .ipad-header {
    bottom: 8%;
  }

  #about_img_container {
    margin-top: 0;
  }

  #about_content {
    height: fit-content;
  }

  .ipad-height {
    height: auto;
  }

  .ipad-carousel {
    top: 33%;
  }
}

@media(max-width: 1030px) and (max-height: 1370px) {
  .ipad-height{
    height: 100vh;
  }

  .ipad-carousel{
    top: 20%;
  }
  .ipad-text{
    font-size: 40px;
  }

  .about-caption {
    font-size: 15px;
    line-height: 1;
  }

  .about-title {
    font-size: 25px;
    line-height: 1;
  }
}

@media(max-width: 768px) and (max-height: 1370px) {
  .ipad-height{
    height: 100vh;
  }

  .ipad-carousel{
    top: 20%;
  }
  .ipad-text{
    font-size: 20px;
  }

  #about_img_container{
    margin-top: 0;
  }
  #about_content{
    height: auto;
  }

  .about-caption {
    font-size: 10px;
    line-height: 1;
  }

  .about-title {
    font-size: 15px;
    line-height: 1;
  }
}

@media (max-width: 500px) {
  .about-caption {
    font-size: 9px;
    line-height: 1;
  }

  .about-title {
    font-size: 13px;
    line-height: 1;
  }

  .about-header {
    top: 25%;
  }
}

@media (min-width: 800px) and (min-height: 1050px) {
  #about_img_container{
    margin-top: 0;
  }
  
  #about_content{
    height: auto;
  }
}

@media (min-width: 950px) and (min-height: 1260px) {
  .ipad-header {
    bottom: 15%;
  }

  
}

@media (min-width: 1100px) and (min-height: 700px) {
  .ipad-header {
    bottom: 25%;
  }

  .ipad-about-img {
    margin-top: -150px;
  }
}
@media (min-width: 1180px) and (min-height: 820px) {
  .ipad-header {
    bottom: 20%;
  }

  .ipad-about-img {
    margin-top: 0;
  }
}

@media (min-width: 1350px) and (min-height: 950px) {
  .ipad-header {
    bottom: 25%;
  }

  .ipad-height{
    height: 100vh;
  }
}

@media (min-width: 1400px) and (min-height: 780px) {
  .ipad-header {
    bottom: 35%;
  }

  .ipad-about-img {
    margin-top: -300px;
  }

  #about_us_height{
    height: calc(100vh + 350px);
  }
}

@media (min-width: 1600px) and (min-height: 780px) {
  #about_us_header{
    top: 18%;
  }

  .ipad-header {
    bottom: 40%;
  }
}