.work-modal-line {
  background-color: #f5f5f5;
  height: 1px;
  right: 100%;
  top: 50%;
  width: 100vw;
}

.inter-font{
  font-family: "Inter";
}

.work-modal-close-btn {
  border: solid white 1px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.work-modal-close-btn:hover {
  background-color: rgba(255, 255, 255, 0.702);
}

.privacy-modal-close-btn {
  border: solid white 1px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.privacy-modal-close-btn:hover {
  background-color: rgba(255, 255, 255, 0.702);
}

.carousel .control-dots {
  z-index: 10 !important;
}

@media (max-width: 800px) and (max-height: 1000px) {
    .work-modal-line {
      width: 0;
    }
  
    .work-modal-close-btn {
      position: absolute;
      top: 5%;
    }
  }

/* @media (max-width: 768px) {
  .work-modal-line {
    width: 100%;
  }
}


@media (min-width: 760px) and (min-height: 1120px) {
  .work-modal-line {
    width: 105.5%;
  }
}

@media (min-width: 820px) and (min-height: 1180px) {
  .work-modal-line {
    width: 105.5%;
  }
}

@media (min-width: 950px) and (min-height: 1260px) {
  .work-modal-line {
    width: 106.5%;
  }
}

@media (min-width: 1000px) and (min-height: 1300px) {
  .work-modal-line {
    width: 106.8%;
  }
}

@media (min-width: 1180px) and (min-height: 820px) {
  .work-modal-line {
    width: 107%;
  }
}

@media (min-width: 1300px) and (min-height: 1000px) {
  .work-modal-line {
    width: 107.4%;
  }
}

@media (min-width: 1366px) and (min-height: 900px) {
  .work-modal-line {
    width: 107.5%;
  }
}

@media (min-width: 1400px) and (min-height: 700px) {
  .work-modal-line {
    width: 107.8%;
  }

  .work-modal-close-btn {
    position: relative;
  }
} */
